:root {
  --color-primary: #1677ff;
  --color-active: #4096ff;
  --color-danger: #ff4d4f;
  --color-green: #49b464;

  --color-black: #000;
  --color-grey: #fafafa;
  --color-dark-grey: #f0f0f0;
  --color-white: #fff;

  --color-grey-text: rgba(0,0,0,.45);

  --color-border: rgba(5, 5, 5, 0.06);

  --form-border-color: #d9d9d9;
  --form-border-color-active: var(--color-active);
  --form-border-color-error: var(--color-danger);

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #1f1f1f;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}

input,
button,
textarea,
select {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
